<template>
<div>
    
</div>
  
</template>

<script>
export default {
    name : "Payment"

}
</script>
